<template>
	<div>
		<div class="box">
			<CustomTable
				id_table="accounting"
				:items="filtredAccounting"
				:busy.sync="table_busy"
				primaryKey="accountingplan_id"
				:hide_if_empty="true"
				:hrefsRoutes="config_table_hrefs"
				:columsAdd="columsAdd"
				:externSlotColumns="['accountingplan_supplier']"
			>
				<template v-slot:[`custom-slot-cell(accountingplan_supplier)`]="{ data }">
					<b-form-checkbox
						class="custom-control custom-checkbox float-right mr-2"
						v-model="data.accountingplan_supplier"
						name="accountingplan_supplier" 
						@input="switchAccountingSupplier(data)"
						switch
					>
					</b-form-checkbox>
				</template>
			</CustomTable>

			<b-modal ref="modal" hide-footer>
				<template v-slot:modal-title>
					{{ $t("compta.ajouter_comptabilite") }}
				</template>

				<AccountingAjout @ok="onComptabiliteAdded" />
			</b-modal>

			<b-modal ref="modalDuplicate" hide-footer>
				<template v-slot:modal-title>
					{{ $t("compta.dupliquer_comptabilite") }}
				</template>

				<AccountingAjout @ok="onComptabiliteAdded" :duplicate="true" :accountingplan_id="accountingplan_id"/>
			</b-modal>

			<ModalConfirm 
				ref="modal_regenerate_client_account" 
				id="1"
				:icon="['fal', 'repeat-alt']"
				:text_title="$t('modal.accounting.regeneration')"
				:text_question="$t('modal.accounting.confirmation_regeneration')"
				:text_message="$t('modal.general.avertissement_suppression')"
				:text_button_ok="$t('action.regenerer')"
				:text_button_cancel="$t('global.annuler')"
				:callback_validation="applyRegenerateClientAccount"
				:callback_cancel="cancelRegenerateClientAccount" />
		</div>
	</div>
</template>


<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
	import Accounting from "@/mixins/Accounting.js"
	import Shutter from "@/mixins/Shutter.js"

	export default {
		name: "Accounting",
		mixins: [Config, TableAction, Navigation, Accounting, Shutter],
		props: ['code_onboarding'],
		data () {
			return {
				accounting: null,
				table_busy: true,
				accountingplan_id: null,
				events_tab: {
					'TableAction::goToRegenerateClientAccount': (params) => {
						this.accountingplan_id = params.accountingplan_id
						this.$refs.modal_regenerate_client_account.openModal()
					},
					'TableAction::goToDuplicateAccountingPlan': (params) => {
						this.accountingplan_id = params.accountingplan_id
						this.$refs.modalDuplicate.show()
					},
                    'TableAction::goToAddComptabilite': () => {
						this.$refs.modal.show()
                    },
                    'TableAction::goToEditComptabilite': (params) => {
						this.$router.push({
							name: 'AccountingInformations',
							params: {
								accountingplan_id: params.accountingplan_id
							},
							query: {
								mode: 'edit'
							}
						})
                    },
					'TableAction::goToArchiveAccounting': (accountingplan_ids) => {
						this.archiveAccountingPlan(accountingplan_ids)
						.then(() => {
							this.loadAccountingListe()
						})
					}
				},
				config_table_hrefs: {
					'accountingplan_label': {
						routeUniqueName: 'AccountingInformations',
						routeName: 'AccountingInformations',
						params: {
							accountingplan_id: 'accountingplan_id'
						}
					},
					'tiers.tiers_rs': {
						routeUniqueName: 'tiersFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'tiers.tiers_id'
						}
					}
				},
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.loadAccountingListe()
			},
			async loadAccountingListe() {
				this.table_busy = true
				this.accounting = await this.loadAccountingPlans()
				this.table_busy = false
			},
			async applyRegenerateClientAccount() {
				await this.regenerateClientAccount(this.accountingplan_id)
				EventBus.$emit('TableAction::stopSpin')
				this.$refs.modal_regenerate_client_account.closeModal()
			},
			cancelRegenerateClientAccount() {
				EventBus.$emit('TableAction::stopSpin')
				this.$refs.modal_regenerate_client_account.closeModal()
			},
			onComptabiliteAdded() {
				this.$refs.modal.hide()
				this.$refs.modalDuplicate.hide()

				if(this.code_onboarding) {
					const params =  {
						code: this.code_onboarding,
						done: 1,
						skip: 0
					}

					this.ok(params)
					this.shutterPanel().close(this.code_onboarding)
				}
				else {
					this.loadAccountingListe()
				}
			},
			async switchAccountingSupplier(accounting) {
				const accounting_infos = this.accounting.find(acc => acc.accountingplan_id == accounting.accountingplan_id)
				if(accounting_infos.accountingplan_supplier == accounting.accountingplan_supplier) {
					return false
				}

				if(accounting.accountingplan_supplier) {
					const limit = await this.checkHasReachEntitySupplierLimit()

					if(limit) {
						this.failureToast('toast.compta_supplier_limit')
						this.loadAccountingListe()
						return false
					}
				}

				await this.editAccountingPlanSupplier(accounting.accountingplan_id, accounting.accountingplan_supplier)
				this.successToast('toast.info_save_succes')
			}
		},
		computed: {
			filtredAccounting: function() {
				return this.accounting
			},
			columsAdd: function() {
				return this.$store.state.userAccess.hasInvoiceSupplierOCR ? ['accountingplan_supplier'] : ''
			}
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			AccountingAjout: () => import('@/components/Accounting/AccountingAjout'),
			ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
		}
	}
</script>
